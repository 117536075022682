.catalog-filters {
    position: fixed;
    left: var(--offset-x);
    bottom: var(--offset-x);
    z-index: 1;
    width: 40vw;
    background-color: $black;
    display: none;
    gap: 1px;
    transition:
        opacity $app-default-duration * 2 $app-default-easing,
        visibility $app-default-duration * 2 $app-default-easing,
        transform $app-default-duration * 2 $app-default-easing;

    @include tablet {
        width: calc(65% - var(--offset-x));
    }

    @include horizontal-tablet {
        width: calc(48% - var(--offset-x));
    }

    @include mobile {
        gap: 8px 1px;
        flex-wrap: wrap;
        width: calc(100% - var(--offset-x) * 2);
        bottom: calc(var(--app-nav-height) + var(--app-filters-offset) + 16px);
    }

    .catalog-filter-btn--compare {
        display: none;

        @include mobile {
            display: inline-block;
        }
    }

    .catalog-filter-btn {
        flex: 1 1 auto;
        order: 1;

        .btn__inner,
        .btn__copy,
        .btn__outer {
            --padding-x: 20px;
            --padding-y: 16px;
            width: 100%;
            height: 100%;
            justify-content: space-between;
        }

        .btn__icon {
            --size: 16px;
        }

        @include large-desktop {
            .btn__inner,
            .btn__copy {
                --padding-y: 28px;
            }
        }

        @include mobile {
            flex: 1 1 calc(100% / 3 - 12px);

            .btn__inner,
            .btn__copy {
                --padding-x: 14px;
                --padding-y: 6px;
            }

            &:first-child,
            &:nth-child(4) {
                flex: 0 1 calc(44.44% - 5px);

                .btn__inner,
                .btn__copy {
                    --padding-y: 12px;
                }
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5) {
                --padding-y: 4px;
            }

            &.catalog-filter-btn--filter {
                order: 2;
            }

            &.catalog-filter-btn--sort {
                order: 3;
            }

            &.catalog-filter-btn--favorite {
                order: 0;
            }
        }

        @include small-mobile {
            .btn__inner,
            .btn__copy {
                --padding-x: 10px;
            }
        }
    }
}

.catalog-filters--hidden {
    transform: translateY(150%);

    @include tablet {
        transform: translateY(0px);
    }
}

.catalog-filters--all-pages {
    display: flex;
    width: 30vw;

    .catalog-filter-btn--filter,
    .catalog-filter-btn--sort {
        display: none;
    }

    .catalog-filter-btn--compare {
        display: inline-block;
    }

    .catalog-filter-btn--favorite {
        order: 0;
    }

    @include tablet {
        width: calc(48.5% - var(--offset-x));
    }

    @include mobile {
        gap: 1px;
        flex-wrap: wrap;
        width: calc(100% - var(--offset-x) * 2);

        .catalog-filter-btn {
            @include mobile {
                flex: 1 1 calc(100% / 3 - 6px);

                .btn__inner,
                .btn__copy {
                    --padding-x: 14px;
                    --padding-y: 6px;
                }

                &:last-child {
                    flex: 1 1 calc(100% / 3 - 6px);

                    .btn__inner,
                    .btn__copy {
                        --padding-y: 6px;
                    }
                }

                &.btn-primary.btn-sm {
                    --padding-y: 4px;
                }
            }

            @include small-mobile {
                .btn__inner,
                .btn__copy {
                    --padding-x: 10px;
                }
            }
        }
    }
}

.catalog-filter-btn {
    flex: 1 1 100%;

    .btn__inner,
    .btn__copy,
    .btn__outer {
        width: 100%;
        height: 100%;
        justify-content: center;
    }
}

.catalog-popup-title {
    text-transform: uppercase;

    @include tablet {
        align-self: flex-start;

        @include responsive-font-size(14px, 20px, 360px, 1024px);
    }
}

.catalog-popup-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 84px;

    @include laptop {
        margin-bottom: 44px;
    }

    @include tablet {
        margin-bottom: 96px;
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 40px;
    }

    @include mobile {
        margin-bottom: 60px;
    }
}

.filters-footer {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding-block: 20px;
    margin-top: 42px;
    border-top: 1px solid rgba($gray, 0.3);
    position: absolute;
    z-index: 1;
    bottom: 0;
    bottom: calc(100lvh - 100dvh);
    width: 100%;
    background-color: $black-200;
    z-index: 2;

    @include laptop {
        gap: 32px;
        margin-top: 20px;
    }

    @include tablet {
        gap: 40px;
        padding-block: 48px;
        margin-top: 68px;
    }

    @include mobile {
        padding-block: 24px;
        margin-top: 40px;
    }
}
