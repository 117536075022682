.footer {
    --page-bottom-offset: 0px;
    --bottom-offset: 116px;
    position: relative;
    margin-top: auto;
    padding-top: 212px;
    padding-bottom: calc(var(--bottom-offset) + var(--page-bottom-offset));
    display: flex;
    flex-direction: column;
    gap: 212px;

    @include laptop {
        gap: 160px;
        padding-top: calc(var(--header-height) + 20px);
        --bottom-offset: 95px;
    }

    @include tablet {
        gap: 30px;
        padding-top: 116px;
        --bottom-offset: calc(var(--app-nav-height) + 100px);
        position: relative;
    }

    .catalog-page &,
    .search-page & {
        --page-bottom-offset: calc(var(--app-nav-height) + 66px);

        @include laptop {
            --page-bottom-offset: calc(var(--app-nav-height) + 56px);
        }
    }

    .footer-email {
        padding-left: 0;
        padding-right: 0;
    }
}

.footer-top {
    align-self: center;
    text-align: center;
    max-width: 375px;
    text-wrap: balance;

    @include laptop {
        max-width: 325px;
    }

    @include tablet {
        max-width: 340px;
    }
}

.footer-center {
    position: relative;
    display: flex;
    justify-content: space-around;

    @include laptop {
        justify-content: space-between;
    }

    @include tablet {
        flex-direction: column;
        align-items: center;
        position: static;
    }

    @include mobile {
        padding-bottom: 30px;
    }
}

.footer-up-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    margin-inline: 0;

    @include laptop {
        top: 70px;
    }

    @include tablet {
        transform: none;
        position: relative;
        left: unset;
        top: unset;
    }
}

.footer-socnets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 16px;
    width: 600px;

    @include large-desktop {
        width: 27vw;
    }

    @include laptop {
        gap: 0 20px;
        width: 520px;
    }

    @include tablet {
        width: 460px;
        gap: 0 4px;
    }

    @include mobile {
        width: 100%;
    }
}

.footer-contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;

    @include large-desktop {
        width: 25.73vw;
    }

    @include laptop {
        width: 470px;
    }

    @include tablet {
        width: 100%;
        padding-bottom: 30px;
    }
}

.footer-left,
.footer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 212px;

    @include laptop {
        gap: 160px;
    }
}

.footer-right {
    @include tablet {
        gap: 30px;
    }
}

.footer-right__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-top: 22px;

    @include laptop {
        padding-top: 16px;
    }

    @include tablet {
        padding-top: 0;
    }
}

.copyright .btn__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.copyright__text {
    line-height: 1;
}

.copyright__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100%;
        height: auto;
    }

    @include mobile {
        width: 25px;
    }
}

.rotor-copyright {
    margin-top: auto;
    @include tablet {
        position: absolute;
        bottom: calc(20px + var(--app-nav-height) + var(--app-filters-offset));
        left: 20px;
    }

    @include mobile {
        --offset: 50px;
        left: 37px;
        bottom: calc(var(--app-nav-height) + var(--app-filters-height));

        .catalog-page & {
            --offset: 80px;
        }
    }
}

.chipsa-copyright {
    min-height: unset;
    margin-top: auto;
    @include tablet {
        position: absolute;
        bottom: calc(20px + var(--app-nav-height) + var(--app-filters-offset));
        right: 20px;
    }

    @include mobile {
        --offset: 50px;
        right: 37px;
        bottom: calc(var(--app-nav-height) + var(--app-filters-height));

        .catalog-page & {
            --offset: 80px;
        }
    }
}

.oferta-link {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0.5;

    @include tablet {
        bottom: calc(20px + var(--app-nav-height) + var(--app-filters-offset));
    }

    @include mobile {
        position: relative;
        bottom: unset;
        left: unset;
        transform: none;
        margin-top: 50px;
    }
}

.footer-warning {
    opacity: 0.5;

    &.footer-warning--desktop {
        position: absolute;
        bottom: 24px;
        left: var(--offset-x);
        max-width: 300px;
        text-decoration: underline;

        @include tablet {
            display: none;
        }
    }

    &.footer-warning--tablet {
        display: none;
        position: static;
        max-width: 250px;
        text-align: center;
        padding-bottom: 30px;

        @include tablet {
            display: block;
        }
    }
}
