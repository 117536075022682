.article-hero {
    --offset: calc(var(--header-height) + var(--header-offset));
    aspect-ratio: calc(1920 / 720);
    position: relative;
    display: flex;
    flex-direction: column;

    @include tablet {
        margin-top: var(--offset);
        height: calc((100vh - var(--offset)) * 0.75);
        aspect-ratio: auto;
    }

    @include mobile {
        height: auto;
        aspect-ratio: calc(360 / 416);
    }
}

.article-hero__image {
    @include absolute-picture(cover, absolute);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.2);
        z-index: 1;
    }
}

.article-hero__inner {
    --offset: 96px;
    position: relative;
    z-index: 2;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--offset);

    @include laptop {
        --offset: 80px;
    }

    @include tablet {
        --offset: calc(72px + var(--app-filters-height) + var(--app-filters-offset));
    }

    @include mobile {
        --offset: 32px;
    }
}

.article-title {
    margin-top: auto;
}

.article-back-button {
    position: absolute;
    top: calc(var(--header-height) + 60px);
    top: var(--header-height);
    left: var(--offset-x);
    z-index: 3;

    @include laptop {
        top: calc(var(--header-height) + 26px);
    }

    @include tablet {
        top: 22px;
    }

    @include mobile {
        top: 20px;
    }
}
