.viewed-page {
    .page {
        --top-offset: 56px;
        padding-top: calc(var(--header-height) + var(--top-offset));

        @include laptop {
            --top-offset: 48px;
        }

        @include tablet {
            --top-offset: 38px;
        }

        @include mobile {
            --top-offset: 16px;
        }
    }

    .compare-title {
        width: 30%;
        text-align: left;

        @include tablet {
            width: 50%;
        }
    }

    .compare-tab-controls {
        justify-content: start;
    }

    .compare-tabs-content {
        margin-top: 120px;

        @include laptop {
            margin-top: 80px;
        }

        @include tablet {
            margin-top: 96px;
        }
    }

    .cards-list {
        margin-top: 0;
    }

    .cards-list__item:last-child {
        margin-left: 2px;
    }

    .card {
        padding: 32px var(--x-offset);
    }

    .card-default {
        .card-characteristics {
            padding-bottom: 42px;

            @include laptop {
                padding-bottom: 26px;
            }

            @include mobile {
                margin-top: 22px;
                padding-bottom: 22px;
            }
        }
    }

    .catalog-filter-btn {
        .btn__inner {
            @include mobile {
                padding: 0;
            }
        }

        @include mobile {
            display: none;
            padding: 10px 22px;
        }
    }

    .catalog-popup-header {
        @include mobile {
            margin-bottom: 126px;
        }
    }
}

.controls-filter-btns {
    display: block;
    margin-left: auto;

    @include mobile {
        margin-left: 0;
    }
}

.controls-tab-categories {
    @include mobile {
        display: none;
    }
}

.btn-mustache-wrapper {
    display: flex;
    gap: 10px;
}
