.new-catalog-slider {
    min-height: 100vh;
    padding-top: 142px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow: clip;

    @media screen and (max-width: 1366px) {
        padding-top: 130px;
    }

    @media screen and (max-width: 767px) {
        padding-top: 10px;
    }
}

.new-catalog-slider__top {
    display: flex;
    align-items: flex-end;
    margin-bottom: 64px;
    position: relative;

    @include tablet {
        margin-bottom: 130px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 120px;
        text-align: center;
    }
}

.new-catalog-slider__pagination {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;

    @include tablet {
        position: static;
        margin-left: 25vw;
        margin-right: auto;
        transform: none;
    }

    @include vertical-tablet {
        margin-left: auto;
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
        margin-top: 20px;
        margin-right: auto;
    }

    @include horizontal-mobile {
        margin-right: 0;
        margin-left: auto;
    }
}

.new-catalog-slider__title {
    max-width: 15ch;
}

.new-catalog-slider-el-wrapper {
    padding-left: var(--offset-x);
    padding-right: var(--offset-x);
    flex: 1 1 auto;
}

.new-catalog-slider-el {
    height: 100%;
    &,
    &.swiper {
        overflow: visible;
    }

    &:not(.swiper) {
        justify-content: flex-end;
        margin-block: 3%;
    }

    .swiper-wrapper {
        transition-timing-function: easeOutCubic;
    }

    display: flex;
    gap: 2px;
}

.new-catalog-slider-el__slide {
    flex-shrink: 0;

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active,
    &:not(.swiper-slide) {
        z-index: 1;

        .card {
            background-color: $black-200;
            transition-delay: 0.25s;

            &::before {
                transform: scale(1, 1.15);
                transition-delay: 0.25s;
            }
        }

        .card-header {
            transform: translate3d(0, -140px, 0);
            transition-delay: 0.25s;

            @include laptop {
                transform: translate3d(0, -80px, 0);
            }
        }

        .card-center {
            transform: translate3d(0, -70px, 0);
            transition-delay: 0.25s;

            @include laptop {
                transform: translate3d(0, -40px, 0);
            }
        }

        .card-footer,
        .card-favorite--index {
            opacity: 1;
            visibility: visible;
            transform: none;
            transition-delay: 0.25s;
        }
    }

    .card-header,
    .card-center {
        transition: transform 0.65s easeOutCubic;
    }

    .card.card-width-wide .card-header {
        align-items: flex-start;
    }

    .card-center {
        transform: translate3d(0, 100px, 0);

        @include laptop {
            transform: translate3d(0, 70px, 0);
        }
    }

    .card-name {
        transform-origin: 0% 0%;

        @media screen and (max-width: 767px) {
            transform-origin: 100% 0%;
        }
    }

    .card {
        overflow: visible;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: inherit;
            transform-origin: 0% 100%;
            transition: transform 0.65s easeOutCubic;
        }
    }

    .card-footer,
    .card-favorite--index {
        transition:
            opacity 0.3s ease,
            visibility 0.3s ease,
            transform 0.65s easeOutCubic;
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, 20px, 0);
    }

    &,
    &.swiper-slide {
        width: calc(100% / 3);
        max-width: 100%;
    }

    &:not(.swiper-slide) {
        --columns: 4;
        width: calc(100% / var(--columns));

        @include laptop {
            --columns: 3;
        }

        @include tablet {
            --columns: 1.5;
        }

        @include mobile {
            --columns: 1;
        }

        .card {
            height: 100%;
        }
    }
}
