.main-services-section {
    --offset-top: var(--header-height);
    min-height: 100vh;
    padding-top: var(--offset-top);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow: clip;

    --title-height: 48px;
    --title-offset: 48px;

    @include large-desktop {
        --title-offset: 64px;
    }

    @include tablet {
        --title-offset: 24px;
        padding-bottom: calc(var(--app-nav-small-height) + 32px);
        --offset-top: calc(var(--header-height) + 32px);
        min-height: auto;
    }

    @include mobile {
        --title-offset: 20px;
        --offset-top: var(--header-height);
        padding-bottom: calc(var(--app-nav-small-height) + 10px);
    }
}

.main-services-section__top {
    display: flex;
    align-items: flex-end;
    margin-bottom: var(--title-offset);
    position: relative;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}
