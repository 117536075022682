.service-cards-list {
    display: flex;
    --gap: 2px;
    gap: var(--gap);
}

.service-cards-list__item {
    width: calc(100% / 3 - var(--gap) / 3 * 2);
}

.service-cards-slider {
    max-width: 100%;
    height: 100%;
    .swiper {
        width: 100%;
    }
}

.services-slider-pagination {
    margin-left: auto;
    margin-top: calc((var(--title-height) + var(--title-offset)) * -1);
    margin-bottom: 36px;
    margin-right: var(--offset-x);

    @include mobile {
        margin-inline: auto;
        margin-bottom: 40px;
        margin-top: 0;
    }
}
