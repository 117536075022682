.favorites-popup {
    visibility: hidden;
    transition: visibility 0s ease;
    transition-delay: 0.5s;
    z-index: 17;
    height: 100%;

    .popup-container {
        position: absolute;
        top: 0;
        right: 0;
        left: unset;
        width: 492px;
        overflow: hidden;

        @include large-desktop {
            width: 25.625vw;
        }

        @include laptop {
            width: 25.78vw;
        }

        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: 100%;
        }
    }

    .popup-overlay {
        background-color: $black;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    &.popup--opened {
        visibility: visible;
        transition-delay: 0s;

        .popup-overlay {
            opacity: 0.74;
        }
    }
}

.favorites-popup__inner {
    background-color: $black-100;
    color: $white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.favorite-header {
    padding: 48px 19px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 22px;

    @include horizontal-mobile {
        padding: 16px 19px 8px 24px;
    }
}

.favorite-footer {
    padding: 24px 24px 60px 24px;

    @include horizontal-mobile {
        padding: 24px;
    }
}

.favorite-title {
    display: flex;
    align-items: flex-start;
    text-transform: uppercase;
    gap: 8px;
}

.favorite-count {
    opacity: 0.6;
    padding-top: 0.33em;
}

.favorite-empty-message {
    padding-inline: 24px;
}

.favorite-empty-message__icon {
    margin-inline: 8px;
}

.favorite-popup-close {
    align-self: flex-end;
}

.favorite-body {
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: unset;
    scrollbar-width: unset;
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 36px;

    @include laptop {
        gap: 12px;
        margin-top: 20px;
    }

    @include tablet {
        gap: 20px;
        margin-top: 24px;
    }

    @include horizontal-mobile {
        margin-top: 0;
    }

    @include mobile {
        gap: 18px;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: rgba($white, 0.01);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba($white, 0.5);
        border-radius: 0px;
        width: 8px;
        height: 8px;
        border: 3px solid $black-100;
    }
}

.favorite-all-checkbox {
    margin-left: 24px;
    align-self: flex-start;

    @include laptop {
        margin-left: 20px;
    }
}
