.main-page-articles {
    --offset-top: 90px;
    padding-top: calc(var(--header-height) + var(--offset-top));
    display: flex;
    flex-direction: column;
    --article-top-height: 96px;
    overflow: hidden;
    overflow: clip;

    @include laptop {
        --offset-top: 32px;
    }

    @include tablet {
        --offset-top: 50px;
        --article-top-height: 194px;
    }

    @include mobile {
        --offset-top: 0px;
    }
}

.main-page-articles-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 28px;
}

.main-page-articles-title {
    display: flex;
    align-items: flex-start;
    gap: 6px;
}

.main-page-articles-count {
    opacity: 0.6;
}

.article-items-list-pagination {
    margin-bottom: 24px;
    margin-inline: auto;
}
